const name_options = [
    {
        name: "shirt 01",
    },
    {
        name: "shirt 02",
    },
    {
        name: "shirt 03",
    },
    {
        name: "shirt 04",
    },
]
const type_options = [
    // {
    //     name: "Business shirt",
    // },
    // {
    //     name: "portswear",
    // },
    // {
    //     name: "portswear 03",
    // },
    // {
    //     name: "portswear 04",
    // },
    'CV',
    'DX',
    'HP',
    'MF',
    'PP',
    'PPTFE-laminated',
    'PU',
    'WPBF',
    'WPBF',
]

import i18n from "@/i18n"

const structure_options = [
    // {
    //     name: "loose fit / 3D",
    // },
    // {
    //     name: "loose fit / -",
    // },
    // {
    //     name: "just fit / 3D",
    // }
    {
        label: i18n.global.t('attribute.shell'),
        value: 'shell'
    },
    {
        label: i18n.global.t('attribute.interlayer'),
        value: 'interlayer'
    },
    {
        label: i18n.global.t('attribute.lining'),
        value: 'lining'
    },
]
const tickness_options = [
    {
        name: "loose fit / 3D",
    },
    {
        name: "loose fit / -",
    },
    {
        name: "just fit / 3D",
    }
]
const weight_options = [
    {
        name: "loose fit / 3D",
    },
    {
        name: "loose fit / -",
    },
    {
        name: "just fit / 3D",
    }
]
const density_option = [
    {
        name: "loose fit / 3D",
    },
    {
        name: "loose fit / -",
    },
    {
        name: "just fit / 3D",
    }
]
const supplier_options = [
    {
        name: "Bivolino",
    },
    {
        name: "Bivolino 2",
    },
    {
        name: "Bivolino 3",
    },
    {
        name: "Bivolino 4",
    },
    {
        name: "Bivolino 5",
    },
    {
        name: "Bivolino 6",
    },
    {
        name: "Bivolino 7",
    },
    {
        name: "Bivolino 8",
    },
]
const tableInfo = [
    {
        id: "100011",
        name: "Jean",
        shape: "",
        type: "100011",
        size: "nip.nie@goldendata.cloud",
        feel: "good",
        skin_comfort: "YSR",
        heat_humidity: "Management",
        brand: "Bivolino",
        supplier: "Bivolino",
        cost: "80",
        stock: '66',
        mmt: '',
        ftt: ''
    },
    {
        id: "100012",
        name: "Jean",
        shape: "https://fs.goldendata.cloud/thrillerbark/20220801/Alabasta云图设计-1920-01.png",
        type: "100012",
        size: "nip.nie@goldendata.cloud",
        feel: "good",
        skin_comfort: "YSR",
        heat_humidity: "Production Leader",
        brand: "Bivolino",
        supplier: "Bivolino",
        cost: "80",
        stock: '66',
        mmt: '',
        ftt: ''
    },
    {
        id: "100013",
        name: "Jean",
        shape: "https://fs.goldendata.cloud/thrillerbark/20220801/Alabasta云图设计-1920-01.png",
        type: "100013",
        size: "nip.nie@goldendata.cloud",
        feel: "good",
        skin_comfort: "YSR",
        heat_humidity: "Depot Operator",
        brand: "Bivolino",
        supplier: "Bivolino",
        cost: "80",
        stock: '66',
        mmt: '',
        ftt: ''
    },
    {
        id: "100014",
        name: "Jean",
        shape: "https://fs.goldendata.cloud/thrillerbark/20220801/Alabasta云图设计-1920-01.png",
        type: "100013",
        size: "nip.nie@goldendata.cloud",
        feel: "good",
        skin_comfort: "Production",
        heat_humidity: "Plant Operator",
        brand: "Bivolino",
        supplier: "Bivolino",
        cost: "80",
        stock: '66',
        mmt: '',
        ftt: ''
    },
    {
        id: "100015",
        name: "Jean",
        shape: "https://fs.goldendata.cloud/thrillerbark/20220801/Alabasta云图设计-1920-01.png",
        type: "100015",
        size: "nip.nie@goldendata.cloud",
        feel: "good",
        skin_comfort: "Quality Inspection",
        heat_humidity: "Lab Operator",
        brand: "Bivolino",
        supplier: "Bivolino",
        cost: "80",
        stock: '66',
        mmt: '',
        ftt: ''
    },
]

/* 品目编码 */
const code = [
    '5007 丝或绢丝机织物',
    '5111 粗梳羊毛或粗梳动物细毛的机织物',
    '5112 精梳羊毛或精梳动物细毛的机织物',
    '5113 动物粗毛或马毛的机织物',
    '5208 棉机织物，按重量计含棉量在 85％及以上，每平方米重量不超过 200 克',
    '5209 棉机织物，按重量计含棉量在 85％及以上，每平方米重量超过 200 克',
    '5210 棉机织物，按重量计含棉量在 85％以下，主要或仅与化学纤维混纺，每平方米重量不超过 200 克',
    '5211 棉机织物，按重量计含棉量在 85％以下，主要或仅与化学纤维混纺，每平方米重量超过 200 克',
    '5212 其他棉机织物',
    '5309 亚麻机织物',
    '5310 黄麻或品目 5303 的其他纺织用韧皮纤维机织物',
    '5311 其他纺织用植物纤维机织物；纸纱线机织物',
    '5407 合成纤维长丝纱线的机织物，包括品目 5404 所列材料的机织物',
    '5408 人造纤维长丝纱线的机织物，包括品目 5405 所列材料的机织物',
    '5512 合成纤维短纤纺制的机织物，按重量计合成纤维短纤含量在 85%及以上',
    '5513 合成纤维短纤纺制的机织物，按重量计合成纤维短纤含量在 85%以下，主要或仅与棉混纺，每平方米重量不超过 170 克',
    '5514 合成纤维短纤纺制的机织物，按重量计合成纤维短纤含量在 85％以下，主要或仅与棉 混纺，每平方米重量超过 170 克',
    '5515 合成纤维短纤纺制的其他机织物',
    '5516 人造纤维短纤纺制的机织物',
    '5801 起绒机织物及绳绒织物，但品目 5802 或 5806 的织物除外',
    '5802 毛巾织物及类似的毛圈机织物，但品目 5806 的狭幅织物除外；簇绒织物，但品目 5703的 产品除外',
    '5803 纱罗，但品目 5806 的狭幅织物除外',
    '5804 网眼薄纱及其他网眼织物，但不包括机织物、针织物或钩编织物;成卷、成条或成小块图案的花边，但品目 6002 的织物除外',
    '5805 哥白林、"弗朗德"、"奥步生"、"波威"及类似式样的手织装饰毯，以及手工针绣嵌花装饰毯（例如，小针脚或十字绣），不论是否制成的',
    '5806 狭幅机织物，但品目 5807 的货品除外；用粘合剂粘合制成的有经纱而无纬纱的狭幅织物（包扎匹头用带）',
    '5809 其他品目未列名的金属线机织物及品目 5605 所列含金属纱线的机织物，用于衣着、装 饰及类似用途',
    '5810 成匹、成条或成小块图案的刺绣品',
    '5811 用一层或几层纺织材料与胎料经绗缝或其他方法组合制成的被褥状纺织品，但品目5810 的刺绣品除外',
    '6001 针织或钩编的起绒织物，包括"长毛绒"织物及毛圈织物',
    '6002 宽度不超过 30 厘米，按重量计弹性纱线或橡胶线含量在 5％及以上的针织物或钩编织物，但品目 6001 的货品除外',
    '6003 宽度不超过 30 厘米的针织或钩编织物，但品目 6001 或 6002 的货品除外',
    '6004 宽度超过30厘米，按重量计弹性纱线或橡胶线含量在5％及以上的针织物或钩编织物，但品目 6001 的货品除外',
    '6005 经编织物（包括由花边针织机织成的），但品目 6001 至 6004 的货品除外',
    '6006 其他针织或钩编织物'
]
const membrane_location_options = [
    {
        label: 'Inner',
        value: 'inner'
    },
    {
        label: 'Outer',
        value: 'outer'
    },
    {
        label: 'Middle',
        value: 'middle'
    },
]
export {
    name_options,
    type_options,
    structure_options,
    tickness_options,
    weight_options,
    density_option,
    supplier_options,
    tableInfo,
    code,
    membrane_location_options,
}
