<template>
  <div class="position-relative" style="padding-top: 0.5rem;">
    <div class="tip-content start">
      0
    </div>
    <div class="tip-content end">
      1
    </div>
    <div class="circle-bg d-flex justify-content-center align-items-center cursor-pointer" :style="calStyle" v-b-tooltip.hover :title="value">
      <div class="circle-point" :class="calClass"></div>
    </div>
    <div :class="[
      barType == 'skin_roughness' ? 'roughness-bar' : '',
      barType == 'hand_roughness' ? 'roughness-bar revert' : '',
      barType == 'skin_softness' ? 'softness-bar' : '',
      barType == 'hand_softness' ? 'softness-bar revert' : '',
      barType == 'skin_warmth' ? 'warmth-bar revert' : '',
      barType == 'hand_warmth' ? 'warmth-bar' : '',
      barType == 'comfort_value' ? 'comfort_value-bar' : '',
      barType == 'dampness_sensation' ? 'dampness_sensation-bar' : '',
      barType == 'thermal_sensation' ? 'thermal_sensation-bar' : '',
    ]"></div>
    <div class="d-flex justify-content-between w-100 position-absolute" style="top: 12px;margin-top: 1rem;">
      <div class="position-relative w-100">
        <span class="tiptext-0">{{ tipText0 }}</span>
        <span class="tiptext-1">{{tipText1}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import {percentUI} from "@/utils/common";

export default {
  props: {
    barType: {
      type: String,
      default: null,
    },
    value: {
      type: [Number, String],
      default: 0,
    }
  },
  computed: {
    calStyle() {
      return {
        left: `calc(${percentUI(Number(this.value))}% - 9px)`
      };
    },
    calClass() {
      if (this.barType == 'hand_roughness' || this.barType == 'skin_roughness') {
        return 'roughness'
      }
      if (this.barType == 'hand_softness' || this.barType == 'skin_softness') {
        return 'softness'
      }
      if (this.barType == 'hand_warmth' || this.barType == 'skin_warmth') {
        return 'warmth'
      }
      if (this.barType == 'comfort_value') {
        return 'comfort_value'
      }
      if (this.barType == 'dampness_sensation') {
        return 'dampness_sensation'
      }
      if (this.barType == 'thermal_sensation') {
        return 'thermal_sensation'
      }
      return ''
    },
    tipText0() {
      if (this.barType == 'hand_roughness') {
        return this.$t('rough')
      }
      if (this.barType == 'skin_roughness') {
        return this.$t('smooth')
      }
      if (this.barType == 'hand_softness') {
        return this.$t('stiff')
      }
      if (this.barType == 'skin_softness') {
        return this.$t('soft')
      }
      if (this.barType == 'hand_warmth') {
        return this.$t('cool')
      }
      if (this.barType == 'skin_warmth') {
        return this.$t('warm')
      }
      if (this.barType == 'comfort_value') {
        return this.$t('comfortable')
      }
      if (this.barType == 'dampness_sensation') {
        return this.$t('dry')
      }
      if (this.barType == 'thermal_sensation') {
        return this.$t('cool')
      }

      return ''
    },
    tipText1() {
      if (this.barType == 'hand_roughness') {
        return this.$t('smooth')
      }
      if (this.barType == 'skin_roughness') {
        return this.$t('prickle')
      }
      if (this.barType == 'hand_softness') {
        return this.$t('soft')
      }
      if (this.barType == 'skin_softness') {
        return this.$t('stiff')
      }
      if (this.barType == 'hand_warmth') {
        return this.$t('warm')
      }
      if (this.barType == 'skin_warmth') {
        return this.$t('cool')
      }
      if (this.barType == 'comfort_value') {
        return this.$t('uncomfortable')
      }
      if (this.barType == 'dampness_sensation') {
        return this.$t('damp')
      }
      if (this.barType == 'thermal_sensation') {
        return this.$t('warm')
      }
      return ''
    },
  }
}

</script>
<style lang="scss" scoped>
.roughness-bar {
  position: absolute;
  width: 100%;
  height: 12px;
  background: linear-gradient(270deg, #0061A1 11.76%, #35A5DB 53.02%, #BCD9F1 94.28%);
}
/*与roughness-bar的背景颜色相反*/
.roughness-bar.revert {
  background: linear-gradient(270deg, #BCD9F1 11.76%, #35A5DB 53.02%, #0061A1 94.28%);
}
.softness-bar {
  position: absolute;
  width: 100%;
  height: 12px;
  background: linear-gradient(270deg, #D6BC0E 11.76%, #EAE58C 53.02%, #F4FDCA 94.28%);
}
.softness-bar.revert {
  background: linear-gradient(270deg, #F4FDCA 11.76%, #EAE58C 53.02%, #D6BC0E 94.28%);
}
.warmth-bar {
  position: absolute;
  width: 100%;
  height: 12px;
  background: linear-gradient(270deg, #43B549 11.76%, #B3B241 53.02%, #F9B03B 94.28%);
  transform: rotate(179.74deg);
}
.warmth-bar.revert {
  background: linear-gradient(270deg, #F9B03B 11.76%, #B3B241 53.02%, #43B549 94.28%);
}
.comfort_value-bar {
  position: absolute;
  width: 100%;
  height: 12px;
  background: linear-gradient(270deg, #F8C07E 11.76%, #A5BECC 53.02%, #035D98 94.28%);
}
.dampness_sensation-bar {
  position: absolute;
  width: 100%;
  height: 12px;
  background: linear-gradient(270deg, #1377B9 3.48%, #C7C0CE 48.88%, #FE7CAD 94.28%);
  opacity: 0.5;
}
.thermal_sensation-bar{
  position: absolute;
  width: 100%;
  height: 12px;
  background: linear-gradient(270deg, #076D37 11.76%, #539F3C 53.02%, #8BC63F 94.28%);
}
.circle-bg {
  position: absolute;
  z-index: 10;
  top: 3px;
  width: 21px;
  height: 21px;
  border-radius: 21px;
  background: #D6F6E9;

  .circle-point {
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 11px;
    background: #0161A1;
    &.roughness {
      background: #0161A1;
    }
    &.softness {
      background: #E5D881;
    }
    &.warmth {
      background: #43B549;
    }
    &.comfort_value {
      background: #F9C07F;
    }
    &.dampness_sensation {
      background: #89BBDC;
    }
    &.thermal_sensation {
      background: #086E38;
    }
  }
}

.tip-content {
  position: absolute;
  color: #000;
  text-align: center;
  line-height: 26px;
  width: 22px;
  height: 31px;
  background-image: url("~@/assets/img/message.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0 0;
}
.tip-content.start {
  left: -11px;
  bottom: 6px;
}
.tip-content.end {
  right: -11px;
  bottom: 6px;
}
.tiptext-0 {
  position: absolute;
  left: -10px;
}
.tiptext-1 {
  position: absolute;
  right: -10px;
}






</style>
